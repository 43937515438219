import React from 'react'
import Slider from 'react-slick';

const TestList = {
    loop: true,
    responsive: [
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 11,
                autoplaySpeed: 2000,
                easing: "linear"
              },
        },
        {
            breakpoint: 1000,
            settings: {
              slidesToShow: 4,
            },
          },
    ]
}

function Menulist() {
    return (
        <>
            <div className="menu_list_section">
                <Slider className="Slider-List" id="menu_list_flow" {...TestList} slidesToShow={9} autoplay={true} speed={1000} arrows={false} autoplaySpeed={0} easing='linear'>
                <div className="item">
                        <div className="text_block">
                            <span>Unutulmaz Anlar</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span>Masal Gibi</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span>Sonsuz Aşk</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span>Mutluluk Dolu </span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span>Rüya Gibi</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span>Birlikte Sonsuza</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span>Özel Gününüz</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span>Mükemmel Uyum</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span> Sevgi Dolu </span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span>Efsanevi Düğün</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span>Eğlence</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                    <div className="item">
                        <div className="text_block">
                            <span>Atmosfer</span>
                            <span className="mark_star">*</span>
                        </div>
                    </div>
                </Slider>
            </div>
        </>
    )
}

export default Menulist