import React from 'react'
import Abt1 from '../../assets/images/abtbanshape.png'
import contactShape from '../../assets/images/contactShape.png'
import contact from '../../assets/images/contact.png'

function Contactblock() {
    return (
        <>
            <section className="contact_us_section">
                <div className="right_shape position-absolute">
                    <img src={Abt1} alt="img" />
                </div>
                <div className="left_shape position-absolute">
                    <img src={contactShape} alt="img" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                                <img className="radius_img" src={contact} alt="img" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="contact_box">
                                <ul className="contact_listing" data-aos="fade-up" data-aos-duration={1500} >
                                    <li>
                                        <p className="sub_title">Telefon</p>
                                        <div className="cnt_block">
                                            <a href="tel:+905334438636">0533 443 86 36</a>
                                            <span>
                                                <i className="icofont-ui-call" />
                                            </span>
                                        </div>
                                        {/* <div className="cnt_block">
                                            <a href="tel:+10987654321">+1 098 765 4321</a>
                                            <span>
                                                <i className="icofont-whatsapp" />
                                            </span>
                                        </div> */}
                                    </li>
                                    <li>
                                        <p className="sub_title">Email</p>
                                        <div className="cnt_block">
                                            <a href="mailto:">info@seramoni.com</a>
                                            <span>
                                                <i className="icofont-envelope-open" />
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <p className="sub_title">Adres</p>
                                        <div className="cnt_block">
                                            <p>Fethiye/Muğla</p>
                                            <span>
                                                <i className="icofont-location-pin" />
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                                {/* <form data-aos="fade-up" data-aos-duration={1500}>
                                    <h3>Say hi, Don’t be shy!</h3>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Your Name" />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Email" />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Phone Number"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" placeholder="Message" defaultValue={""} />
                                    </div>
                                    <div className="form-group text-right">
                                        <button type="submit" className="btn btn_primary">
                                            SUBMIT
                                        </button>
                                    </div>
                                </form> */}
                            </div>
                        </div>
                    </div>
                    <div className="map" data-aos="fade-up" data-aos-duration={1500}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d102430.03308364525!2d29.039468393638!3d36.65192929884377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c0439b0db6b13b%3A0x87150704244f70d7!2zRmV0aGl5ZSwgTXXEn2xh!5e0!3m2!1str!2str!4v1721047224214!5m2!1str!2str"
                            height={450} style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contactblock