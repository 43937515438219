import React from 'react'
import Fry from '../../assets/images/french_fry.png'
import Icon1 from '../../assets/images/outj_icon1.png'
import Icon2 from '../../assets/images/outj_icon2.png'
import Icon3 from '../../assets/images/outj_icon3.png'

function Ourjourny() {
    return (
        <>
            <section className="row_am our_journey bg_yellow_textcher">
                <div className="side_shape position-absolute" data-aos="fade-in" data-aos-duration={1500} >
                    <img src={Fry} alt="img" />
                </div>
                <div className="container">
                    <div className="row_am">
                        <div className="section_title" data-aos="fade-up" data-aos-duration={1500} >
                            <span className="sub_text">Seramonİ Organİzasyon:
                            </span>
                            <h2>
                                Etkİnlİklerİnİzde <br />unutulmaz anlar yaşayın
                            </h2><br />
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="joueney_box" data-aos="fade-up" data-aos-duration={1500} >
                                    {/* <div className="icon">
                                        <img src={Icon1} alt="img" />
                                    </div> */}
                                    <h3>ADRES</h3>
                                    <p>
                                   Muğla Fethiyede <br /> bulunan Seramoni Organizasyon <br />ofisimizi ziyaret edin

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="joueney_box" data-aos="fade-up" data-aos-duration={1500} >
                                    {/* <div className="icon">
                                        <img src={Icon2} alt="img" />
                                    </div> */}
                                 <h3>HİZMETLERİMİZ</h3>
<p>
    Düğün, nişan, doğum günü, kurumsal etkinlikler ve daha fazlası için  Seramoni Organizasyon'un profesyonel hizmetlerinden yararlanın.
</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="joueney_box" data-aos="fade-up" data-aos-duration={1500} >
                                    {/* <div className="icon">
                                        <img src={Icon3} alt="img" />
                                    </div> */}
                                   <h3>MÜŞTERİ MEMNUNİYETİ</h3>
<p>
    Her etkinlikte misafirlerinizin <br /> unutulmaz anılar biriktirmesi için <br /> müşteri memnuniyetini ön planda tutuyoruz.
</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Ourjourny