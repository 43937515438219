import React from 'react';
import { Link } from 'react-router-dom';
import Img from '../../assets/images/side_burger.png';
import Chefimg from '../../assets/images/master_chef.png';


const chefData = {
        "chefName": " unutulmaz kılın",
        "chefTitle": " özel anlarınızı",
        "chefExperience": "unutulmaz kılın",
        "chefDescription1": "Seramoni Organizasyon, etkinlik planlama ve yönetimi alanında uzmanlaşmış bir şirkettir. Düğünlerden kurumsal etkinliklere, özel davetlerden lansmanlara kadar geniş bir yelpazede hizmet sunmaktayız. Profesyonel ekibimizle, her detayı titizlikle planlayarak hayallerinizdeki etkinliği gerçeğe dönüştürmekteyiz.  Misafirlerinizin unutamayacağı anılar biriktirmesi için özgün ve yaratıcı çözümler üretiyoruz. ",
        "chefDescription2": "Kaliteli hizmet anlayışımızla, müşteri memnuniyetini her zaman ön planda tutarak sizlere en iyi deneyimi yaşatmayı hedefliyoruz. Seramoni Organizasyon ile etkinliklerinizde fark yaratın! , Seramoni Organizasyon, etkinlik planlama ve yönetimi alanında uzmanlaşmış bir şirkettir. Düğünlerden kurumsal etkinliklere, özel davetlerden lansmanlara kadar geniş bir yelpazede hizmet sunmaktayız. Profesyonel ekibimizle, her detayı titizlikle planlayarak hayallerinizdeki etkinliği gerçeğe dönüştürmekteyiz.  Misafirlerinizin unutamayacağı anılar biriktirmesi için özgün ve yaratıcı çözümler üretiyoruz. ",
};

function Chef() {
    return (
        <>
            <section className="chef_section chef_page row_am">
                <div className="right_shape chef_shape position-absolute">
                    <img src={Img} alt="img" />
                </div>
                <div className="container">
                    <div className="row my-2 my-sm-4">
                        <div className="col-md-6" data-aos="fade-up" data-aos-duration={1500}>
                            <div className="chef_img">
                                <img className="radius_img img-fluid" src={Chefimg} alt="img" />
                            </div>
                            <h3 className="chef_name">
                                <span>{chefData.chefTitle}</span> {chefData.chefName}
                            </h3>
                        </div>
                        <div className="col-md-6">
                            <div className="abt_chef" data-aos="fade-up" data-aos-duration={1500}>
                                {/* <div className="banner_badge">
                                    <img className="img-fluid" src={Chefcap} alt="img" />
                                    <div className="round_anim">
                                        <img src={Text} alt="img" />
                                    </div>
                                </div> */}
                                <div className="section_title">
                                    <span className="sub_text">Size Özel</span>
                                    <h2 className="mb-3">{` ${chefData.chefTitle.toLowerCase()}  ${chefData.chefExperience}`}</h2>
                                    <p>{chefData.chefDescription1}</p>
                                    <p>{chefData.chefDescription2}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Chef;
